@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@500;600;700&family=Inter:wght@500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
  background-color: #fff;
  overflow-x: hidden;
}
body.ar {
  font-family: 'Cairo', sans-serif;
  direction: rtl;
}

.boxShadow {
  box-shadow: 0px 8px 24px 0px #F3F2F6;
}
.boxShadow2 {
  box-shadow: 0px 12.424032211303711px 32.15631866455078px 0px #B3B3B340;
}

pre {
  font-family: 'Inter', sans-serif;
  white-space: pre-wrap;
  overflow: inherit;
}
body.ar pre {
  font-family: 'Cairo', sans-serif;
  direction: rtl;
}

.special-label {
  display: none;
}
.form-control  {
  padding-top: 25px !important;
  padding-bottom: 27px !important; 
  padding-left: 50px !important;
  padding-right: 50px !important; 
  margin-top: 1.5rem !important; 
  border-width: 2px !important; 
  outline-style: none !important; 
  width: 100% !important; 
  border-color: #E8E8E8 !important;
  border-radius: 0.75rem !important;
}

.flag-dropdown {
  border-top-left-radius: 0.75rem !important; 
  border-bottom-left-radius: 0.75rem !important; 
}
@media (max-width: 992px) {
  .resNav {
    box-shadow: 0px 8px 28px 0px #594EF126;
  }
  .react-tel-input {
    height: 26px;
  }
  .form-control {
    padding-top: 0px !important;
    padding-bottom: 0px !important; 
    margin-top: 0.75rem !important; 
    border-radius: 0.375rem !important;
  }
  .form-control::placeholder {
    font-size: 8px;
  }
  .form-control {
    font-size: 10px !important;
    height: 100% !important;
  }
  .flag-dropdown {
    border-top-left-radius: 0.375rem !important; 
    border-bottom-left-radius: 0.375rem !important; 
    padding-top: 0px !important;
    padding-bottom: 0px !important; 

  }
  body.ar .selected-flag {
    display: flex;
    justify-content: center;
  }
  body.ar .arrow {
    left: -20px !important;
  }
}

.slider-trackMd {
  width: calc(377px * 12);
  overflow-x: hidden;
}
.slider-track {
  width: calc(146px * 12);
  overflow-x: hidden;
}

@layer components {
  ::selection {
    @apply bg-sec text-white
  }
  ::-webkit-scrollbar {
    @apply bg-white w-4
  }
  ::-webkit-scrollbar-thumb {
    @apply bg-sec rounded-3xl
  }
  .flexCenter {
    @apply flex justify-center items-center
  }
  .flexBetween {
    @apply flex justify-between items-center
  }
  .btn {
    @apply bg-sec px-8 py-[14px] pc:px-[7px] pc:py-2 rounded-full text-white lg:text-xl text-base font-bold  flexBetween w-fit gap-2
  }
  .resNav {
    @apply  gap-3 pc:absolute flex pc:w-full pc:bg-white pc:top-20 pc:left-0 pc:rounded-xl
    pc:flex-col transition-all pc:z-30 pc:py-1
  }
  .minTitle {
    @apply  text-text md:leading-9 leading-7 md:text-[22.55px] text-[16px] mt-5 text-center md:w-2/5 w-[95%] mx-auto
  }
  .pause {
    animation-play-state: paused;
  }
}


@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}

.carousel-container {
  overflow: hidden;
  position: relative;
  height: 400px;;
 
  width: 100%;
}

.carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
 margin-left: 20px;
  border-radius: 20%;
  
}

 

.carousel-track {
  display: flex;
  position: absolute;
  left: 0;
  justify-content: center;
  align-items: center;
  height: 80px;
  
  width: 220%;
  height: 100%;
  animation: slide 20s linear infinite;
  &:hover {
      animation-play-state: paused;
  }
}

@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}